@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
      --primary: #1e293b;
      --primary-foreground: #f8fafc;
    }
    html[data-theme='dark'] {
      --primary: #cbd5e1;
      --primary-foreground: #0f172a;
    }
    html[data-theme='tangerine'] {
      --primary: #fcd34d;
      --primary-foreground: #0f172a;
    }
    html[data-theme='dark-tangerine'] {
      --primary: #d97706;
      --primary-foreground: #0f172a;
    }
    html[data-theme='mint'] {
      --primary: #6ee7b7;
      --primary-foreground: #0f172a;
    }
    html[data-theme='dark-mint'] {
      --primary: #047857;
      --primary-foreground: #f8fafc;
    }
  }